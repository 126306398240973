import React, { useEffect } from "react";
import Banner from "../common/home_banner";
import AppBar from "../common/AppBar.js";
import Header from "../common/Header";
import Footer from "../common/footer";
import LowerFooter from "../common/lower-footer";

import { Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
// import Button from '@material-ui/core/Button';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";

// import bicLogo from '../../assets/img/bic_logo_final.png';
// import bincyLogo from '../../assets/img/BICNY_Logo.png';
import OnlineGiving from "../../assets/img/OnlineGiving.png";
import DonateFromMobile from "../../assets/img/DonateFromMobile.png";
import SiteKiosks from "../../assets/img/On-SiteKiosks.png";
// import MH from '../../assets/img/MH.png';
// import ici from '../../assets/img/ici.png';
// import nationIslam from '../../assets/img/nation-islam.png';
// import ICCL from '../../assets/img/ICCL.png';
// import TheIslamic from '../../assets/img/The-Islamic.png';
// import ICNE from '../../assets/img/ICNE.png';
// import ICGA from '../../assets/img/ICGA.png';

import QDCLogo from "../../assets/img/org_logo/QDC_Logo.png";
import BICNYLogo from "../../assets/img/org_logo/BICNY_Logo.png";
import MUNALogo from "../../assets/img/org_logo/deenconnect-masjid-umme-hani-ra-florida.png";
import MCEFLogo from "../../assets/img/org_logo/MCEF_Logo.png";
import DarulJannahLogo from "../../assets/img/org_logo/DarulJannah.png";
import MASJIDSABLogo from "../../assets/img/org_logo/Masjid_Baitus_Salam_Logo.png";
import MasjidTauheedLogo from "../../assets/img/org_logo/MasjidTauheed.png";
import DEENCONNECTHHCLog from "../../assets/img/org_logo/DEENCONNECT_HHC_Log.png";
import TheAbrarLog from "../../assets/img/org_logo/theAbrar-logo-donation.png";
import QuranAcademyLog from "../../assets/img/org_logo/quran-academy-logo.png";
import Ahic from "../../assets/img/org_logo/al-hikmah-islamic-center.png";
import mudarib from "../../assets/img/org_logo/deenfund-mudarib-foundation.webp";
import mcb from "../../assets/img/org_logo/muna-center-of-buffalo.png";
import munaAcademy from "../../assets/img/org_logo/deenfund-muna-academy.png";

import listIcon from "../../assets/img/list_icon.png";

import Carousel from "./carousel";
import TabContent from "./tabContent";

import "./style.css";

const useStyles = makeStyles((theme) => ({
  FD_organization: {
    textAlign: "center",
    paddingTop: "96px",
  },
  inner_Body: {
    padding: "0 24px",
  },
  FD_organTitle: {
    color: "#294C5C",
    fontSize: "36px",
    fontWeight: 600,
    margin: "0px 0 5px 0",
    "@media(max-width:425px)": {
      fontSize: "28px",
    },
  },
  FD_line: {
    background: "#BFCED7",
    height: "3px",
    maxWidth: "100px",
    borderRadius: "4px",
    margin: "15px auto",
  },
  FD_line2: {
    height: "2px",
    margin: "0 auto",
    maxWidth: "100px",
    background: "#DAE2E9",
    borderRadius: "100px",
    marginTop: "15.24px",
  },
  FD_organText: {
    color: "#4C5E67 !important",
    fontSize: "22px !important",
    opacity: 0.8,
    fontFamily: "Open Sans",
    lineHeight: "1.5 !important",
    letterSpacing: "1px",
    "@media(max-width:425px)": {
      fontSize: "18px !important",
    },
  },
  FD_organizationSection: {
    maxWidth: "1140px",
    margin: "0 auto",
  },
  FD_card: {
    display: "inherit",
    textAlign: "center",
    padding: "15px 15px 25px",
    transition: "0.3s",
    "&:hover": {
      border: "1px solid #05CE78",
      boxShadow: "0px 9px 26px #4E4E4E30",
      "& $FD_donateBtn": {
        background: "#05CE78",
        color: "#fff !important",
        transition: "0.3s",
        border: "none",
      },
      "& $FD_organName": {
        color: "#05CE78",
      },
    },

    "@media(max-width:640px)": {
      height: "320px",
    },

    "@media(max-width:600px)": {
      height: "auto",
    },
  },
  FD_organName: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#294C5C",
    marginBottom: 0,
    marginTop: "20px",
    transition: "0.3s",
  },
  FD_address: {
    color: "#4C5E67",
    fontSize: "18px",
    fontFamily: "Open Sans",
    width: "80%",
    margin: "10px auto",
    lineHeight: 1.6,
    opacity: 0.8,
  },
  FD_donateBtn: {
    background: "#fff",
    color: "#05CE78 !important",
    transition: "0.3s",
    border: "1px solid #E4E8EC",
    marginTop: "25px",
    width: "140px",
    height: "45px",
    display: "block",
  },
  FD_card_FD_donateBtn: {
    "&:hover": {
      background: "#05CE78",
      color: "#fff !important",
      transition: "0.3s",
      border: "none",
    },
  },
  FD_goodness: {
    background: "#6E90A8",
    paddingTop: "80px",
    paddingBottom: "40px",
    color: "#fff",
    textAlign: "center",
  },
  FD_goodnessContent: {
    paddingRight: 10,
    paddingLeft: 10,
    maxWidth: "1140px",
    margin: "0 auto",
  },
  FD_goodnessTitle: {
    fontSize: "28px",
    fontWeight: "bold",
  },
  FD_goodnessText: {
    opacity: 0.8,
    fontSize: "26px",
    lineHeight: 1.5,
    padding: "0 50px",
  },
  FD_DonateImages: {
    width: "186px",
  },
  FD_donateTitle: {
    fontSize: "23px",
    fontWeight: "bold",
    marginTop: "30px",
    marginBottom: 0,
    "@media(max-width:425px)": {
      fontSize: "22px",
    },
  },
  FD_donateText: {
    fontFamily: "Open Sans",
    fontSize: "20px",
    lineHeight: 1.5,
    margin: "18px 0px",
    "@media(max-width:425px)": {
      fontSize: "14px",
    },
  },
  FD_trustedOrganization: {
    textAlign: "center",
    color: "#294C5C",
    padding: "50px 0px",
    "@media (max-width:1200px) ": {
      padding: "50px 24px",
    },
  },
  FD_trustedTitle: {
    fontWeight: "bold",
    fontSize: "36px",
    margin: "23.24px 0px",
    "@media(max-width:425px)": {
      fontSize: "28px",
    },
  },
  FD_trustedTitle2: {
    fontWeight: "bold",
    fontSize: "36px",
    textTransform: "uppercase",
    "@media(max-width:425px)": {
      fontSize: "28px",
    },
  },
  FD_trustedText: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    opacity: 0.8,
    lineHeight: 1.5,
    color: "#4C5E67",
    "@media(max-width:425px)": {
      fontSize: "18px",
    },
  },
  FD_trustedGrid: {
    maxWidth: "1140px",
    margin: "0 auto",
  },

  FD_ImgTitle: {
    margin: "10px 0 0",
    fontSize: "18px",
    color: "#294C5C",
  },
  FD_ImgText: {
    opacity: 0.8,
    fontSize: "16px",
    margin: "8px",
    color: "#4C5E67",
    fontFamily: "Open Sans",
  },
  FD_adSection: {
    background: "#B8B8B8",
    opacity: 0.45,
    paddingTop: "70px",
    paddingBottom: "70px",
  },
  FD_adTitle: {
    fontSize: "64px",
    textAlign: "center",
    fontWeight: "bold",
  },
  tabsStyles: {
    color: "#000000",
    fontWeight: 500,
    fontSize: ".875rem",
    lineHeight: "2.25rem",
    letterSpacing: ".0892857143em",
    textDecoration: "none",
    width: "100%",
  },
  singleTab: {
    color: "#294C5C",
    height: "52px",
    fontSize: "20px",
    opacity: 0.6,
    padding: "0px 12px",
    textTransform: "none",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#F9F7FE",
    },
    "@media (max-width:767px)": {
      display: "block",
      margin: "0 auto",
      maxWidth: "inherit",
    },
    "@media(max-width:425px)": {
      fontSize: "14px",
    },
  },
  bigIndicator: {
    height: 4,
    backgroundColor: "#05CE78",
    "@media(max-width:767px)": {
      display: "none",
    },
    marginTop: "20px",
  },
  activeTextColor: {
    color: "#05CE78",
    "@media (max-width: 767px)": {
      borderBottom: "4px solid #05CE78",
    },
  },
  FD_pagePart: {
    textAlign: "center",
    height: "52px",
  },
  FD_padding_zero: {
    padding: "0 !important",
  },
  DF_empowerTabs: {
    maxWidth: "1140px",
    margin: "50px auto !important",
    height: "auto",
  },
  FD_tabsImgText: {
    padding: "24px 0px",
    maxWidth: "1140px",
    margin: "0 auto",
    width: "100%",
    height: "auto",
  },
  FD_organizationImg: {
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "100%",
  },
  FD_sideTextPart: {
    fontFamily: "Open Sans",
    color: "#4C5E67",
    textAlign: "left",
    fontSize: "22px",
    lineHeight: 2,
    margin: 0,
    fontWeight: 600,
    "@media(max-width:425px)": {
      fontSize: "22px",
    },
  },

  FD_listUl: {
    paddingLeft: 0,
    listStyle: "none",
  },
  FD_listItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#294C5C",
    paddingRight: "0px",
    margin: "30px 0px",
  },
  FD_listImg: {
    paddingRight: "10px",
  },
  FD_noticeTitle: {
    fontSize: "36px",
    fontWeight: "bold",
    margin: "10px 0",
    "@media(max-width:425px)": {
      fontSize: "28px",
    },
  },
  FD_whiteLine: {
    background: "#fff",
    height: "3px",
    maxWidth: "100px",
    borderRadius: "100px",
    margin: "10px auto",
  },
  FD_noticeText: {
    fontFamily: "Open Sans",
    fontSize: "22px",
    opacity: 0.8,
    letterSpacing: "1px",
    "@media(max-width:425px)": {
      fontSize: "18px",
    },
  },
  tabContainer: {
    "@media(min-width:767px)": {
      borderBottom: "4px solid lightgray",
    },
    justifyContent: "space-between",
    "@media(max-width:767px)": {
      display: "block",
    },
  },
  FD_marginAuto: {
    margin: "0 auto",
    padding: 0,
  },
  contentCenter: {
    justifyContent: "center",
  },
  media: {
    marginTop: "19px",
    width: "100%",
  },
  cardImages: {
    width: "90%",
    paddingTop: 10,
    "@media(max-width:768px)": {
      height: "auto",
      width: "100%",
    },

    "@media(max-width:600px)": {
      height: "auto",
      width: "200px",
    },
  },

  orglogo: {
    height: "50px",
    paddingTop: 10,
  },

  city: {
    margin: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    //   'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const embed_form_link = process.env.REACT_APP_EMBED_FORM_LINK;

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, {});

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <AppBar active={0} />
      <Header active={0} />

      <Banner connectingPara="Connecting awesome donors with great causes for the Ummah" />
      <div className="main_container" id="RECENTLY_JOINED_ORGANIZATIONS">
        <Grid container className={classes.FD_organization}>
          <Grid container className={classes.inner_Body}>
            <Grid
              container
              item
              xs={12}
              direction="row"
              alignItems="stretch"
              justify="flex-start"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                direction="column"
                className={classes.FD_aboutTextSection}
              >
                <Typography
                  className={classes.FD_organTitle}
                  variant="h1"
                  component="h1"
                >
                  RECENTLY JOINED ORGANIZATIONS
                  <Divider className={classes.FD_line} />
                </Typography>
                <Typography
                  className={classes.FD_organText}
                  variant="body1"
                  style={{ marginTop: "21px" }}
                  component="p"
                >
                  We are a global platform connecting awesome donors
                </Typography>
                <Typography
                  className={classes.FD_organText}
                  variant="body1"
                  style={{ marginBottom: "21px" }}
                  component="p"
                >
                  to amazing organizations with great causes.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="main_container">
        <Grid
          container
          direction="column"
          className={classes.FD_organizationSection}
        >
          <Grid
            xs={12}
            alignItems="center"
            container
            item
            direction="row"
            className={classes.featuredGird}
            classes={{ root: classes.contentCenter }}
          >
            <Grid container item xs={12} spacing={3} style={{ padding: "0px" }}>
              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img alt="" className={classes.cardImages} src={QDCLogo} />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Al Quran Dawah Center
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    15 Hamilton Drive{" "}
                    <p className={classes.city}>Roslyn, NY 11576</p>
                  </Typography>
                  <a
                    href={`${embed_form_link}/alquran-dawah-center`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img alt="" className={classes.cardImages} src={BICNYLogo} />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Brooklyn Islamic Center, Inc
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    722 Church Avenue{" "}
                    <p className={classes.city}>Brooklyn, NY 11218</p>
                  </Typography>

                  <a href={`${embed_form_link}/bicny`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={DEENCONNECTHHCLog}
                    style={{ maxWidth: "80px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    HELPING HAND FOR CHITTAGONIAN INC
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    73 Mackay Pl{" "}
                    <p className={classes.city}>Brooklyn, NY 11209</p>
                  </Typography>

                  <a href={`${embed_form_link}/hhcusa`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={QuranAcademyLog}
                    style={{ maxWidth: "80px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    QURAN ACADEMY
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    1033 Glenmore Ave{" "}
                    <p className={classes.city}>Brooklyn, New York 11208</p>
                  </Typography>

                  <a href={`${embed_form_link}/qafys`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt="munaAcademy"
                    src={munaAcademy}
                    style={{ height: "50px", width: "160px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    MUNA academy
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    1033 Glenmore Ave
                    <p className={classes.city}>Brooklyn, NY, 11208</p>
                  </Typography>

                  <a
                    href={`${embed_form_link}/muna-academy`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={mudarib}
                    style={{ height: "50px", width: "220px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Mudarib Foundation
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    148-45 Hillside Avenue
                    <p className={classes.city}>Jamaica, NY 11435</p>
                  </Typography>

                  <a href={`${embed_form_link}/mudarib`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={TheAbrarLog}
                    style={{ maxWidth: "80px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    THE ABRAR INC
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    3016 Bailey Ave{" "}
                    <p className={classes.city}>Bronx, New York 10463</p>
                  </Typography>

                  <a
                    href={`${embed_form_link}/theabrar`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={DarulJannahLogo}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Darul Jannah Masjid
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    6 Avenue C{" "}
                    <p className={classes.city}>Brooklyn, NY 11218</p>
                  </Typography>

                  <a
                    href={`${embed_form_link}/darul-jannah`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    alt=""
                    className={classes.cardImages}
                    src={MUNALogo}
                    style={{ maxWidth: "73px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Masjid Umme Hani
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    18215 NW 18th Ave{" "}
                    <p className={classes.city}>Miami Gardens, FL 33056</p>
                  </Typography>

                  <a
                    href={`${embed_form_link}/masjid-umme-hani`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={mcb}
                    style={{ height: "70px", width: "220px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Muna center of buffalo (MCB)
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    651 Broadway
                    <p className={classes.city}>Buffalo, NY 14212</p>
                  </Typography>

                  <a href={`${embed_form_link}/mcb`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={Ahic}
                    style={{ height: "50px", width: "220px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Al-Hikmah Islamic Center
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    75 International Blvd,
                    <p className={classes.city}>Suite 302HW</p>
                  </Typography>

                  <a href={`${embed_form_link}/ahic`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    alt=""
                    className={classes.cardImages}
                    src={MASJIDSABLogo}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Masjid Baitus Salam
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    167 East 43rd Street{" "}
                    <p className={classes.city}>Brooklyn, NY 11203</p>
                  </Typography>

                  <a
                    href={`${embed_form_link}/baitus-salam`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={MasjidTauheedLogo}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    Masjid Tauheed/ICNM
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    820 NE 126th St{" "}
                    <p className={classes.city}>North Miami, FL 33161</p>
                  </Typography>

                  <a
                    href={`${embed_form_link}/masjid-tauheed`}
                    className="donate_btn"
                  >
                    donate now
                  </a>
                </Card>
              </Grid>

              {/* ============ new org ============= */}

              {/* <Grid item md={4} sm={6} xs={12}>
                <Card className={classes.FD_card} variant="outlined">
                  <img
                    className={classes.cardImages}
                    alt=""
                    src={
                      "https://munaconvention.com/admin/assets/highlight/24022018101333amhomepage_log.png"
                    }
                    style={{ height: "50px", width: "220px" }}
                  />
                  <Typography
                    className={classes.FD_organName}
                    variant="h3"
                    component="h3"
                  >
                    MUNA Convention
                  </Typography>
                  <Typography
                    className={classes.FD_address}
                    variant="body1"
                    component="p"
                  >
                    117 N Broad St
                    <p className={classes.city}>Philadelphia, PA 19107</p>
                  </Typography>

                  <a href={`https://munaconvention.com`} className="donate_btn">
                    donate now
                  </a>
                </Card>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Carousel />

      <Grid direction="column" className={classes.FD_goodness}>
        <div className="main_container">
          <Grid
            container
            direction="column"
            className={classes.FD_goodnessContent}
          >
            <Typography
              className={classes.FD_noticeTitle}
              variant="h1"
              component="h1"
            >
              THERE’S NO ONE WAY TO GOODNESSS
              <Divider className={classes.FD_whiteLine} />
            </Typography>
            <Typography
              className={classes.FD_noticeText}
              variant="body1"
              component="p"
            >
              Donating to your choice of organizations shouldn’t be hard.
            </Typography>
            <Typography
              className={classes.FD_noticeText}
              variant="body1"
              component="p"
            >
              Choose the best way that works for you.
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="stretch"
              spacing="4"
              style={{ paddingTop: "30px", width: "100%", margin: 0 }}
            >
              <Grid
                container
                md={4}
                sm={6}
                xs={12}
                direction="column"
                alignItems="center"
                style={{ padding: "24px" }}
              >
                <img
                  src={OnlineGiving}
                  className={classes.FD_DonateImages}
                  alt=""
                />
                <Typography
                  className={classes.FD_donateTitle}
                  variant="h3"
                  component="h3"
                >
                  Online Giving
                </Typography>
                <Typography
                  className={classes.FD_donateText}
                  variant="body1"
                  component="p"
                >
                  Donate one-time or monthly to your choice of organizations
                  from online
                </Typography>
              </Grid>

              <Grid
                container
                md={4}
                sm={6}
                xs={12}
                direction="column"
                alignItems="center"
                style={{ padding: "24px" }}
              >
                <img
                  src={DonateFromMobile}
                  className={classes.FD_DonateImages}
                  alt=""
                />
                <Typography
                  className={classes.FD_donateTitle}
                  variant="h3"
                  component="h3"
                >
                  Donate From Mobile
                </Typography>
                <Typography
                  className={classes.FD_donateText}
                  variant="body1"
                  component="p"
                >
                  Start donations from the most used device we are all attached
                  to
                </Typography>
              </Grid>

              <Grid
                container
                md={4}
                sm={6}
                xs={12}
                direction="column"
                alignItems="center"
                style={{ padding: "24px" }}
              >
                <img
                  src={SiteKiosks}
                  className={classes.FD_DonateImages}
                  alt=""
                />
                <Typography
                  className={classes.FD_donateTitle}
                  variant="h3"
                  component="h3"
                >
                  On-Site Kiosks (Coming Soon)
                </Typography>
                <Typography
                  className={classes.FD_donateText}
                  variant="body1"
                  component="p"
                >
                  Easiest and fastest way to donate On-site, literally with your
                  finger-tips
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid
        direction="column"
        className={classes.FD_trustedOrganization}
        classes={{ root: classes.contentCenter }}
      >
        <div className="main_container">
          <Typography
            className={classes.FD_trustedTitle}
            variant="h2"
            component="h2"
          >
            TRUSTED BY MANY ORGANIZATIONS
            <Divider
              className={classes.FD_line2}
              style={{ marginTop: "23.24px" }}
            />
          </Typography>
          <Typography
            className={classes.FD_trustedText}
            variant="body1"
            component="p"
          >
            Many organizations are using DEENFUND as their go-to platform
          </Typography>
          <Typography
            className={classes.FD_trustedText}
            variant="body1"
            component="p"
          >
            for collecting donations and serving their communities.
          </Typography>

          <Grid
            container
            direction="row"
            alignItems="stretch"
            className={classes.FD_trustedGrid}
            spacing="4"
            style={{ padding: "24px", width: "100%" }}
            classes={{ root: classes.contentCenter }}
          >
            <Grid
              container
              md={3}
              sm={6}
              xs={12}
              direction="column"
              alignItems="center"
              style={{ padding: "24px" }}
            >
              <img src={QDCLogo} className={classes.orglogo} alt="" />
              <Typography
                className={classes.FD_ImgTitle}
                variant="h4"
                component="h4"
              >
                Al Quran Dawah Center
              </Typography>
              <Typography
                className={classes.FD_ImgText}
                variant="body1"
                component="p"
              ></Typography>
            </Grid>

            <Grid
              container
              md={3}
              sm={6}
              xs={12}
              direction="column"
              alignItems="center"
              style={{ padding: "24px" }}
            >
              <img src={BICNYLogo} className={classes.orglogo} alt="" />
              <Typography
                className={classes.FD_ImgTitle}
                variant="h4"
                component="h4"
              >
                Brooklyn Islamic Center, Inc
              </Typography>
              <Typography
                className={classes.FD_ImgText}
                variant="body1"
                component="p"
              ></Typography>
            </Grid>

            <Grid
              container
              md={3}
              sm={6}
              xs={12}
              direction="column"
              alignItems="center"
              style={{ padding: "24px" }}
            >
              <img src={MUNALogo} className={classes.orglogo} alt="" />
              <Typography
                className={classes.FD_ImgTitle}
                variant="h4"
                component="h4"
              >
                Masjid Umme Hani
              </Typography>
              <Typography
                className={classes.FD_ImgText}
                variant="body1"
                component="p"
              ></Typography>
            </Grid>

            <Grid
              container
              md={3}
              sm={6}
              xs={12}
              direction="column"
              alignItems="center"
              style={{ padding: "24px" }}
            >
              <img src={MASJIDSABLogo} className={classes.orglogo} alt="" />
              <Typography
                className={classes.FD_ImgTitle}
                variant="h4"
                component="h4"
              >
                Masjid Baitus Salam
              </Typography>
              <Typography
                className={classes.FD_ImgText}
                variant="body1"
                component="p"
              ></Typography>
            </Grid>

            <Grid
              container
              md={3}
              sm={6}
              xs={12}
              direction="column"
              alignItems="center"
              style={{ padding: "24px" }}
            >
              <img src={DarulJannahLogo} className={classes.orglogo} alt="" />
              <Typography
                className={classes.FD_ImgTitle}
                variant="h4"
                component="h4"
              >
                Darul Jannah Masjid
              </Typography>
              <Typography
                className={classes.FD_ImgText}
                variant="body1"
                component="p"
              ></Typography>
            </Grid>

            <Grid
              container
              md={3}
              sm={6}
              xs={12}
              direction="column"
              alignItems="center"
              style={{ padding: "24px" }}
            >
              <img src={DEENCONNECTHHCLog} className={classes.orglogo} alt="" />
              <Typography
                className={classes.FD_ImgTitle}
                variant="h4"
                component="h4"
              >
                Helping Hand for Chittagonian, Inc
              </Typography>
              <Typography
                className={classes.FD_ImgText}
                variant="body1"
                component="p"
              ></Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>

      {/* <Grid container xs={12} direction="column" className={classes.FD_adSection} >
                    <Typography className={classes.FD_adTitle} variant="h1" component="h1">
                        AD SPACE
                    </Typography>
            </Grid> */}

      <Grid
        container
        direction="column"
        className={classes.FD_trustedOrganization}
        style={{ paddingTop: 0 }}
        classes={{ root: classes.contentCenter }}
      >
        <Typography
          className={classes.FD_trustedTitle2}
          variant="h2"
          component="h2"
        >
          Empowering Organizations
          <Divider
            className={classes.FD_line}
            style={{ marginTop: "23.24px" }}
          />
        </Typography>
        <Typography
          className={classes.FD_trustedText}
          variant="body1"
          component="p"
        >
          Upgrade your organization with innovative and manageable solutions
        </Typography>
        <Typography
          className={classes.FD_trustedText}
          variant="body1"
          component="p"
        >
          with all-in-one, simple, and easy to use platform
        </Typography>

        <Grid
          className={classes.DF_empowerTabs}
          container
          xs={12}
          direction="row"
          alignItems="flex-start"
        >
          <Tabs
            className={classes.tabsStyles}
            value={value}
            classes={{
              indicator: classes.bigIndicator,
              flexContainer: classes.tabContainer,
            }}
            onChange={handleChange}
            aria-label="tab"
          >
            <Tab
              classes={{
                root: classes.singleTab,
                selected: classes.activeTextColor,
              }}
              label="Simple"
              value={0}
              {...a11yProps(0)}
            />

            <Tab
              classes={{
                root: classes.singleTab,
                selected: classes.activeTextColor,
              }}
              label="Secured"
              value={1}
              {...a11yProps(1)}
            />
            <Tab
              classes={{
                root: classes.singleTab,
                selected: classes.activeTextColor,
              }}
              label="Powerful Integrations"
              value={2}
              {...a11yProps(2)}
            />
            <Tab
              classes={{
                root: classes.singleTab,
                selected: classes.activeTextColor,
              }}
              label="Organization Console"
              value={3}
              {...a11yProps(3)}
            />
            <Tab
              classes={{
                root: classes.singleTab,
                selected: classes.activeTextColor,
              }}
              label="Donor Portal"
              value={4}
              {...a11yProps(4)}
            />
          </Tabs>
        </Grid>

        <TabPanel value={value} index={0}>
          <div className="tab_container">
            <h1 className="tab_title">
              DEENFUND was created to be user-friendly, straightforward, and
              easy to use for both organizations and donors.{" "}
            </h1>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Easy to maintain transactions</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Set up with just a few clicks</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Collect donations with ease</span>
              </li>
            </ul>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Donate at convenient time</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Manage account at one location</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Annual Donation Reports</span>
              </li>
            </ul>
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="tab_container">
            <h1 className="tab_title">
              At DEENFUND, your security matters to us and we take it seriously.
              We know the information you are sharing on the site is personal
              and will remain safe.{" "}
            </h1>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Make donations with no worries</span>
              </li>
              <li>
                <img src={listIcon} alt="" /> <span>Secured transactions</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Robust payment processing</span>
              </li>
            </ul>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Safeguard personal & payment info</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>All organizations are verified</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Credentials verified & protected</span>
              </li>
            </ul>
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div className="tab_container">
            <h1 className="tab_title">
              DEENFUND is connecting donors to organizations from all around the
              globe. In one central location, accounts are
            </h1>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Manage funds with ease</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Update project details</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Direct Donations to account</span>
              </li>
            </ul>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>One location funds management</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Convenient regulation of accounts </span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>
                  Promote community needs managed efficiently and effortlessly.{" "}
                </span>
              </li>
            </ul>
          </div>
        </TabPanel>

        <TabPanel value={value} index={3}>
          <div className="tab_container">
            <h1 className="tab_title">
              DEENFUND is a one central location for organizations to make it
              easy for them to maintain, regulate, and fulfill their community
              needs.
            </h1>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Update in one-for-all location</span>
              </li>
              <li>
                <img src={listIcon} alt="" /> <span>Easy and Convenient </span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Set up funds for projects</span>
              </li>
            </ul>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Daily monitoring of activities</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Promote community events</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Continuous updates for all</span>
              </li>
            </ul>
          </div>
        </TabPanel>

        <TabPanel value={value} index={4}>
          <div className="tab_container">
            <h1 className="tab_title">
              Donors are able to donate to various organizations with amazing
              causes and keep track of their donations.
            </h1>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" /> <span>View organizations</span>
              </li>
              <li>
                <img src={listIcon} alt="" /> <span>Track donations</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Set up monthly donations</span>
              </li>
            </ul>

            <ul className="item_list">
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Check out awesome causes</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Make one time donations</span>
              </li>
              <li>
                <img src={listIcon} alt="" />{" "}
                <span>Convenient – No need for cash</span>
              </li>
            </ul>
          </div>
        </TabPanel>
      </Grid>

      <Footer />
      <LowerFooter />
    </React.Fragment>
  );
};

export default HomePage;
